@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn-blue {
        @apply inline-flex items-center py-2 px-3 lg:py-3 w-max md:w-40 text-xs lg:text-sm justify-center bg-blue-3 text-white rounded hover:bg-purple-4 transition duration-500 ease-in-out;
    }

    .btn-blue-light {
        @apply inline-flex items-center py-3 w-40 text-sm justify-center bg-blue-4 text-white rounded hover:bg-purple-4 transition duration-500 ease-in-out;
    }

    .btn-green {
        @apply inline-flex items-center py-3 px-4 text-sm justify-center bg-green-1 text-white hover:bg-green-2 transition duration-500 ease-in-out;
    }

    .btn-red {
        @apply inline-flex items-center py-3 px-4 text-sm justify-center bg-pink-1 text-white rounded hover:bg-pink-4 transition duration-500 ease-in-out;
    }

    .btn-orange {
        @apply inline-flex items-center px-3 text-sm justify-center bg-orange-2 text-white rounded hover:bg-orange-4 transition duration-500 ease-in-out;
    }

    .btn-purple {
        @apply inline-flex items-center py-3 px-4 text-sm justify-center bg-purple-6 text-white rounded hover:bg-purple-4 transition duration-500 ease-in-out;
    }
    .btn-snapppay {
        @apply inline-flex items-center py-3 px-4 text-sm justify-center bg-[#008EFA] text-white rounded hover:bg-[#008EFA] transition duration-500 ease-in-out;
    }
    .btn-outline-purple {
        @apply inline-flex items-center py-2 px-4 text-sm justify-center border-2 border-purple-6 text-purple-6 rounded hover:bg-purple-6 hover:text-white transition duration-500 ease-in-out;
    }

    .btn-outline-gray {
        @apply rounded border border-gray-7 text-gray-3 py-1 px-2 bg-white hover:border-purple-1 hover:text-purple-1 transition duration-500 ease-in-out
    }

    .btn-gray {
        @apply inline-flex items-center py-3 px-4 text-sm justify-center bg-gray-9 text-white rounded transition duration-500 ease-in-out;
    }

    .orange-tag {
        @apply bg-[#ffd9bb] text-[#ff8322] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .purple-tag {
        @apply bg-[#e3cdfe] text-[#a45dfc] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .purple-2-tag {
        @apply bg-[#cfc4fd] text-[#633ff9] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .blue-light-1-tag {
        @apply bg-[#d1eeff] text-[#6AC9FF] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .blue-light-2-tag {
        @apply bg-[#b1e2ff] text-[#00a2ff] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .orange-light-3-tag {
        @apply bg-[#fbf6f0] text-[#e67318] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .green-tag {
        @apply bg-[#d3f3d4] text-[#71D875] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .gray-tag {
        @apply bg-[#c6c7ca] text-[#464a53] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .gray-2-tag {
        @apply bg-[#e5e6e7] text-[#abafb3] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .red-tag {
        @apply bg-[#f5b4b8] text-[#e10a17] rounded-full leading-4 block w-28 mx-auto p-1.5;
    }

    .date-time {
        @apply border border-[#ECEDEF] bg-[#FBFBFB] rounded p-2 w-max mr-2
    }
    .alert-red{
        @apply bg-[#f5b4b845] text-[#e10a17] rounded-md block w-full text-[13px] mx-auto px-2 py-2;
    }
    .alert-info{
        @apply bg-[#f5b4b845] text-[#e10a17] rounded-md block w-full text-[13px] mx-auto px-2 py-2;
    }
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 100;
    src: url('/public/fonts/woff/IRANYekanXFaNum-Thin.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-Thin.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 200;
    src: url('/public/fonts/woff/IRANYekanXFaNum-UltraLight.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-UltraLight.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 300;
    src: url('/public/fonts/woff/IRANYekanXFaNum-Light.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-Light.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 500;
    src: url('/public/fonts/woff/IRANYekanXFaNum-Medium.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-Medium.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 600;
    src: url('/public/fonts/woff/IRANYekanXFaNum-DemiBold.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-DemiBold.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 800;
    src: url('/public/fonts/woff/IRANYekanXFaNum-ExtraBold.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-ExtraBold.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 900;
    src: url('/public/fonts/woff/IRANYekanXFaNum-Black.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-Black.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 950;
    src: url('/public/fonts/woff/IRANYekanXFaNum-ExtraBlack.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-ExtraBlack.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: 1000;
    src: url('/public/fonts/woff/IRANYekanXFaNum-Heavy.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-Heavy.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: bold;
    src: url('/public/fonts/woff/IRANYekanXFaNum-Bold.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-Bold.woff2') format('woff2');
}

@font-face {
    font-family: IRANYekanXFaNum;
    font-style: normal;
    font-weight: normal;
    src: url('/public/fonts/woff/IRANYekanXFaNum-Regular.woff') format('woff'),
    url('/public/fonts/woff2/IRANYekanXFaNum-Regular.woff2') format('woff2');
}


body, .font-iryekan {
    font-family: IRANYekanXFaNum;
    color: #727272;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.scrollbar-style {
    direction: ltr;
    overflow: auto;
    scrollbar-width: 2px;
}

/* .scrollbar-style::-webkit-scrollbar, body::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.scrollbar-style::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
    background: #ECEDEF;
}

.scrollbar-style::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    background-color: #b1afb4;
    border-radius: 10px;
} */

input::placeholder, input{
    font-family: IRANYekanXFaNum, serif;
}

.dir-rtl {
    direction: rtl;
}


.has-items::after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50px;
    background: #e10a17;
    position: absolute;
    right: 14px;
    top: 1px;
}

input, select {
    outline: none;
}

input::placeholder {
    font-size: 13px;
    color: #abafb3;
}

.page-title {
    position: relative;
    font-size: 15px;
}

.page-title::before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #ecedef;
    position: absolute;
    left: 0;
    top: 14px;
    z-index: -1;
}

.main-content {
    box-shadow: 3px 4px 10px 3px #7272721f;
    border-radius: 3px;
}

.text-9 {
    font-size: 9px;
}

.text-11 {
    font-size: 11px;
}

.text-13 {
    font-size: 13px;
}

.text-17 {
    font-size: 17px;
}

table {
    border-collapse: separate;
    border-spacing: 0 10px;
    overflow-x: auto;
    padding: 1px;
}

table thead tr td {
    padding-bottom: 5px;
    border-bottom: 1px solid #ECEDEF;
}

table tr td {
    text-align: center;
}

table tbody tr {
    box-shadow: 3px 3px 7px 0 #72727230;
    border-radius: 8px;
    position: relative;
}

table tbody tr td {
    padding: 5px 10px;
    width: max-content;
}

@media (max-width: 1024px) {
    table tbody tr td {
        min-width: 100px;
    }

    table.table-td-150 tbody tr td {
        min-width: 150px;
    }
}

table tbody tr.before td:first-of-type, .before-table-style {
    position: relative;
}

table tbody tr.before td:first-of-type::before, .before-table-style::before {
    content: '';
    width: 5px;
    height: 100%;
    background: #abafb3;
    border-radius: 0 8px 8px 0;
    position: absolute;
    right: 0;
    top: 0;
}

.before-table-style.before-rounded::before {
    border-radius: 10px
}

.before-w-4::before {
    width: 4px;
}

.before-w-3::before {
    width: 3px;
}

table tbody tr.before.checked td:first-of-type::before, .before-table-style.checked::before, .before-table-style.before-green::before {
    background: #71d875;
}

table tbody tr.before.before-purple td:first-of-type::before, .before-table-style.before-purple::before {
    background: #633FF9;
}

table tbody tr.before.before-blue td:first-of-type::before, .before-table-style.before-blue::before {
    background: #649FE4;
}

table tbody tr.before.before-red td:first-of-type::before, .before-table-style.before-red::before {
    background: #DC3545;
}

table tbody tr.holded td:first-of-type {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 30px;
}

.table-borderd-tr tbody tr {
    box-shadow: 0 0 0 1px #ECEDEF;
}

.hold-style {
    position: absolute;
    top: 0;
    left: 0;
    width: 97%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffd73494;
    z-index: 5;
    margin-left: 45px;
}

.hold-style p {
    background: white;
    color: #adb1b5;
    padding: 5px 15px;
    border-radius: 5px;
}

.decesion-style {
    background: #464a53;
    color: white;
    position: absolute;
    width: max-content;
    right: 430px;
    top: -3px;
    padding: 0 5px;
    border-radius: 3px;
    font-size: 9px;
}

.prebuy-style {
    background: #464a53;
    color: white;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: max-content;
    bottom: 6px;
    padding: 0 3px;
    border-radius: 3px;
    font-size: 9px;
    line-height: 1.5;
}

.shadow-box {
    box-shadow: 0 0 10px 0 #5555507d;
}

.operation-menu {
    opacity: 0;
}

.show-menu-effect {
    animation: show-menu-effect .3s ease;
    animation-fill-mode: both;
}

@keyframes show-menu-effect {
    from {
        opacity: 0;
        top: 30px
    }
    to {
        opacity: 1;
        top: 35px;
    }
}

.animation-from-top {
    animation: animation-from-top .3s ease;
    animation-fill-mode: both;
}

@keyframes animation-from-top {
    from {
        opacity: 0;
        top: -100px
    }
    to {
        opacity: 1;
        top: 0;
    }
}

.switch {
    position: relative;
    display: inline-flex;
    width: 48px;
    height: 27px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e10a17;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 40px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 21px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50px;
    transform: translateX(20px);
}

input:checked + .slider {
    background-color: #71D875;
}

input:focus + .slider {
    box-shadow: 0 0 1px #71D875;
}


input:disabled + .slider {
    background-color: #ABAFB3;
}

input:disabled + .slider {
    box-shadow: 0 0 1px #ABAFB3;
}

input:checked + .slider:before {
    transform: translateX(0);
}

.coupon-table {
    border-spacing: 0 2px;
}

.sub-groups {
    line-height: 0;
}

.sub-groups .inner-bordered-box {
    border-color: transparent;
    transition: 0.3s ease;
    @apply h-0 leading-none overflow-hidden py-0
}

.sub-groups.show .inner-bordered-box {
    @apply border border-blue-2 py-4 h-auto leading-6
}

.sub-groups td {
    height: 0;
    padding: 0;
    line-height: 0;
    overflow: hidden;
    transition: 0.3s ease;
}

.sub-groups.show td {
    height: auto;
    line-height: 1.7;
    padding: 5px 0;
    background: rgba(204, 203, 203, 0.09);
}

.side-bar, .content-wrapper {
    transition: width .3s ease, margin .3s ease, right 0.3s;
}

.side-bar .sub-menu {
    list-style: circle;
}

.side-bar .sub-menu {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s ease-out;
}

.side-bar .sub-menu.show {
    grid-template-rows: 1fr;
}


.FilterBox {
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
}

.FilterBox.show {
    max-height: 1000px;
    transition: max-height 0.5s ease-in;
}

.inner-wrapper {
    overflow: hidden;
}

.side-bar-menu-item {
    @apply w-full flex pr-4 py-2 my-1 justify-between items-center text-sm rounded-l-xl cursor-pointer group-hover:text-purple-2 group-[.active]:text-purple-2 group-hover:bg-white group-[.active]:bg-white
}

.dropDownMenu {
    position: absolute;
    background: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
    transition: .3s ease;
    height: 0;
    left: 0;
    width: 150px;
    overflow: hidden;
    border-radius: 5px;
    top: 30px;
    z-index: 50;
}

.dropDownMenu.show {
    top: 50px;
    height: auto;
}

.form input, .form select, .form textarea {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.18);
    border: 1px solid #ECEDEF;
    padding: 8px 15px 9px 15px;
    border-radius: 5px;
    flex: 1 1 auto;
    outline: none;
    margin-right: 10px;
}

.input-group {
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18);
    border-radius: 5px;
    display: flex;
    flex: auto;
}

.input-group .input {
    border-radius: 0 6px 6px 0 !important;
    box-shadow: none !important;
}

.input-group .btn {
    border-radius: 6px 0 0 6px;
}

.form input::placeholder, .form textarea {
    font-size: 12px;
}

.form label {
    font-size: 12px;
    color: #464A53;
    text-align: center;
    display: flex;
    align-items: center;
}

.form label:first-of-type {
    text-align: right;
}

.form label:last-of-type {
    text-align: end;
}

.form.grid-form label {
    display: block;
    text-align: right;
}

.form.grid-form input, .form.grid-form select, .form.grid-form textarea {
    margin-right: 0;
    margin-top: 10px;
}

input[type=color] {
    flex: none;
    padding: 0;
    border: none;
    box-shadow: none;
    background: no-repeat;
    width: 40px;
    height: 36px;
    -webkit-appearance: none;
}

input[type=color].w-25 {
    width: 25px;
    height: 25px;
}

input[type=color]::-webkit-color-swatch {
    border: none;
    border-radius: 5px;
    padding: 0;
}

input[type=color]::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 5px;
    padding: 0;
}

.date-time input {
    background: transparent;
    box-shadow: none;
    padding: 0;
}

.shadow-style {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.18);
}

input[type=radio] {
    box-shadow: none;
}

.after-border-b-style::after {
    content: '';
    height: 2px;
    background: #633ff9;
    width: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: .3s ease;
}

.after-border-b-style.after-w-full::after {
    width: 100%;
}

.CustomMultiSelectStyle2 .css-1dlg0r7-control, .CustomMultiSelectStyle2 .css-zq5n4g-control {
    box-shadow: none !important;
    border: 1px solid #ECEDEF;
}

.CustomMultiSelectStyle2 .css-1dlg0r7-control:hover {
    border-color: #d0d0f3 !important;
}

.css-d7co0s-multiValue {
    background: #707070 !important;
}



.box-tr-style {
    box-shadow: 3px 3px 7px 0 #72727230;
    border: 1px solid #ECEDEF;
}

.bordered-input {
    @apply border border-gray-9 rounded py-2 px-3 mr-0
}

.bordered-input-7 {
    @apply border border-gray-7 rounded py-2 px-3 mr-0
}

.bordered-input-7::placeholder {
    font-size: 11px;
}

.tr-orange-border tbody tr, div.tr-orange-border {
    box-shadow: 0 0 0 1px #F8B37C;

}

.tr-orange-border tr td {
    background: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input:disabled {
    background: #ECEDEF;
}

.disabled-bg-white {
    box-shadow: none !important;
    background: white !important;
}

/* Customize the label (the container) */
.checkbox-wrapper {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    width: 100%;
}

/* Hide the browser's default checkbox */
.checkbox-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkbox-wrapper .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid #ABAFB3;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkbox-wrapper:hover input ~ .checkmark {
    background-color: #f3f3f3;
}

/* When the checkbox is checked, add a blue background */
.checkbox-wrapper input:checked ~ .checkmark {
    background-color: #71D875;
    border-color: #71D875;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-wrapper .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-wrapper input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark:after {
    left: 4px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* The container */
.radio-wrapper {
    display: block;
    position: relative;
    padding-right: 30px;
    cursor: pointer;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
}

/* Create a custom radio button */
.radio-wrapper .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-wrapper:hover input ~ .checkmark {
    background-color: #e5e3e3;
}

/* When the radio button is checked, add a blue background */
.radio-wrapper input:checked ~ .checkmark {
    background-color: #EEEEEE;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-wrapper .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-wrapper input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-wrapper .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #8454FF;
}

.bg-none {
    background: transparent;
}

.delete-btn {
    @apply border border-gray-9 bg-gray-7 rounded-full w-5 h-5 aspect-square flex items-center justify-center hover:text-red-1 hover:border-red-1
}

.slidecontainer {
    background: #F0F0F1;
    border-radius: 5px;
}

/* The slider itself */
.customSlider {
    appearance: none;
    width: 100%;
    height: 11px;
    background: transparent;
    outline: none;
    border-radius: 5px;
    transition: opacity .2s;

}


/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.customSlider::-webkit-slider-thumb {
    appearance: none;
    width: 18px;
    height: 13px;
    border-radius: 5px;
    background: #8454FF;
    cursor: pointer;
}

.customSlider::-moz-range-thumb {
    width: 18px;
    height: 13px;
    border-radius: 5px;
    background: #8454FF;
    cursor: pointer;
}

.bg-gradient-green {
    background: linear-gradient(145deg, #23BDB8, #43E794);
    transition: .3s ease;
}

.bg-gradient-green:hover {
    box-shadow: 0 3px 13px 0 #28c3b3;
}

.bg-gradient-pink {
    background: linear-gradient(145deg, #E36CD9, #FE60AE);
    transition: .3s ease;
}

.bg-gradient-pink:hover {
    box-shadow: 0 3px 13px 0 #E36CD9;
}

.bg-gradient-purple {
    background: linear-gradient(145deg, #9A56FF, #D78AFD);
    transition: .3s ease;
}

.bg-gradient-purple:hover {
    box-shadow: 0 3px 13px 0 #9A56FF;
}

.bg-gradient-orange {
    background: linear-gradient(145deg, #F48665, #FDA23F);
    transition: .3s ease;
}

.bg-gradient-orange:hover {
    box-shadow: 0 3px 13px 0 #F48665;
}

.bg-gradient-red {
    background: linear-gradient(145deg, #F46565, #FD603F);
    transition: .3s ease;
}

.bg-gradient-red:hover {
    box-shadow: 0 3px 13px 0 #F46565;
}

.purple-shadow:hover {
    transition: .3s ease-in-out;
    box-shadow: 0 3px 13px 0 rgba(87, 36, 161, 0.39);
}
.box-shadow-bottom{
  box-shadow: 0 14px 7px 0px rgb(161 160 160 / 12%)
}
.w-42rem{
    width: 42rem;
}
@media (min-width: 768px){
    .after-border-l::after{
    content: '';
    height: 80%;
    width: 1px;
    background: #ecedef;
    position: absolute;
    left: 0;
    top: 10%;
    }
}
.Toastify__toast-container {
    width: fit-content;
}
.w-content{
    width: max-content;
}
.input-x {
    width: 100%;
    padding: 5px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    font-size: 13px;
    border-radius: 4px;
    box-sizing: border-box;
}
#loading-x{
    position: fixed;
    left: 10px;
    bottom: 10px;
    width: 70px;
    height: 70px;
    padding: 0px;
    place-content: center;
    background-color: rgba(0, 0, 0, 0.83);
    border-radius:100px;
    z-index: 99999;
}
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.xloader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid #f2ecff;
    animation:
            l20-1 0.8s infinite linear alternate,
            l20-2 1.6s infinite linear;
}
@keyframes l20-1{
    0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
    12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
    25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
    50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
    62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
    75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
    100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{
    0%    {transform:scaleY(1)  rotate(0deg)}
    49.99%{transform:scaleY(1)  rotate(135deg)}
    50%   {transform:scaleY(-1) rotate(0deg)}
    100%  {transform:scaleY(-1) rotate(-135deg)}
}

/* HTML: <div class="loader"></div> */
.x2-loader {
    width: 10px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: rgba(0, 0, 0, 0.48) #0000;
    animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}
.leaflet-control-container {
    display: none;
}