ul.stanalone {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: space-between;
}

ul.stanalone li {
    background: #eaeaeacc;
    padding: 5px 7px;
    width: 49%;
    border-radius: 5px;
}
ul.stanalone li.show__item {
    display: none;
}