.access-restricted {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right bottom, #1d0c43, #ce000057);
}

.access-restricted h1 {
    margin: 40px 0 20px;
}
